    .kg-toggle-heading-text {
    font-size: 1.1rem;
    font-weight: 700;
}

.has-serif-title .kg-toggle-heading-text {
    font-family: var(--font-serif);
}

.kg-toggle-content > * + * {
    margin: 2.4rem 0 0;
}

.kg-gallery-image {
    cursor: pointer;
}

.kg-image {
    margin-right: auto;
    margin-left: auto;
}

.kg-canvas {
    display: grid;
    grid-template-columns:
        [full-start]
        minmax(4vw, auto)
        [wide-start]
        minmax(auto, 140px)
        [main-start]
        min(640px, calc(100% - 8vw))
        [main-end]
        minmax(auto, 140px)
        [wide-end]
        minmax(4vw, auto)
        [full-end];
}

.kg-canvas > * {
    grid-column: main-start/main-end;
}

.kg-width-wide {
    grid-column: wide-start/wide-end;
}

.kg-width-full {
    grid-column: full-start/full-end;
}

.u-text-format {
    font-size: 1.7rem;
    line-height: 1.8;
}

.u-text-format > * {
    margin-bottom: 0;
}

.u-text-format > * + * {
    margin-top: 3rem;
}

.u-text-format > *:first-child {
    margin-top: 0 !important;
}

.u-text-format > * + p,
.u-text-format > * + ul,
.u-text-format > * + ol {
    margin-top: 2rem;
}

.u-text-format > [id] {
    margin-top: 4rem;
}

.u-text-format > [id] + p {
    margin-top: 1.5rem;
}

.u-text-format h1 {
    font-size: 2.8rem;
    letter-spacing: -0.03rem;
}

.u-text-format h2 {
    font-size: 2.3rem;
    letter-spacing: -0.03rem;
}

.u-text-format h3 {
    color: var(--secondary-text-color);
    font-size: 2rem;
    letter-spacing: -0.03rem;
}

.u-text-format h4 {
    font-size: 1.9rem;
    font-weight: 700;
}

.u-text-format h5 {
    font-size: 1.6rem;
    font-weight: 700;
}

.u-text-format h6 {
    font-size: 1.3rem;
    font-weight: 700;
    text-transform: uppercase;
}

.has-serif-title .u-text-format h1,
.has-serif-title .u-text-format h2,
.has-serif-title .u-text-format h3,
.has-serif-title .u-text-format h4,
.has-serif-title .u-text-format h5,
.has-serif-title .u-text-format h6 {
    font-family: var(--font-serif);
}

.u-text-format a {
    color: var(--brand-color);
    font-weight: 700;
    text-decoration: underline;
}

.u-text-format ul li + li,
.u-text-format ol li + li {
    margin-top: 1rem;
}

.u-text-format > .kg-card,
.u-text-format > .kg-card + * {
    margin-top: 4.5rem;
}

.u-text-format hr,
.u-text-format hr + * {
    margin-top: 4rem;
}

.u-text-format blockquote,
.u-text-format blockquote + * {
    margin-top: 3rem;
}

.post-template .u-text-format h2,
.post-template .u-text-format h3 {
    scroll-margin-top: 8rem;
}

.page-template .u-text-format h2,
.page-template .u-text-format h3 {
    scroll-margin-top: 3rem;
}

.has-serif-body .u-text-format > blockquote,
.has-serif-body .u-text-format > ol,
.has-serif-body .u-text-format > ul,
.has-serif-body .u-text-format > dl,
.has-serif-body .u-text-format > p {
    font-family: var(--font-serif);
}

.u-permalink {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    outline: none;
}

.u-placeholder {
    position: relative;
    z-index: 10;
    background-color: var(--lighter-gray-color);
}

.u-placeholder::before {
    content: '';
    display: block;
}

.u-placeholder.horizontal::before {
    padding-bottom: 50%;
}

.u-placeholder.rectangle::before {
    padding-bottom: 62.5%;
}

.u-placeholder.square::before {
    padding-bottom: 100%;
}

.u-object-fit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.lazyload,
.lazyloading {
    opacity: 0;
}

.lazyloaded {
    opacity: 1;
    transition: opacity 0.5s var(--animation-base);
}

.animate__animated {
    animation-duration: 1s;
    animation-fill-mode: both;
}

@keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
        opacity: 0;
        transform: scale3d(0.7, 0.7, 0.7);
    }

    40% {
        transform: scale3d(1.1, 1.1, 1.1);
    }

    to {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}

.animate__bounceIn {
    animation-name: bounceIn;
    animation-duration: 0.3s;
}

@keyframes zoomOut {
    from {
        opacity: 1;
    }

    50% {
        opacity: 0;
        transform: scale3d(0.7, 0.7, 0.7);
    }

    to {
        opacity: 0;
    }
}

.animate__zoomOut {
    animation-name: zoomOut;
    animation-duration: 0.3s;
}

/* Content refers to styling all page and post content that is
created within the Ghost editor. The main content handles
headings, text, images and lists. We deal with cards lower down. */

/* Default vertical spacing */
.gh-content > * + * {
    margin-top: 2.8rem;
    margin-bottom: 0;
}

/* [id] represents all headings h1-h6, reset all margins */
.gh-content > [id] {
    margin: 0;
}

.has-serif-title .gh-content > [id] {
    font-family: var(--font-serif);
}

/* Add back a top margin to all headings, unless a heading
is the very first element in the post content */
.gh-content > [id]:not(:first-child) {
    margin-top: 4.8rem;
}

/* Add a small margin between a heading and anything after it */
.gh-content > [id] + * {
    margin-top: 1.6rem;
}

.gh-content > [id] + .kg-card {
    margin-top: 2.8rem;
}

/* A larger margin before/after HRs and blockquotes */
.gh-content > hr,
.gh-content > blockquote {
    position: relative;
    margin-top: 4.8rem;
}

.gh-content > hr + *,
.gh-content > blockquote + * {
    margin-top: 4.8rem;
}

/* Cards */
.gh-content :not(.kg-card):not([id]) + .kg-card {
    margin-top: 4.8rem;
}

.gh-content .kg-card + :not(.kg-card) {
    margin-top: 4.8rem;
}

/* Now the content typography styles */
.gh-content > h2 {
    font-size: 2.3rem;
}

.gh-content > h3 {
    font-size: 2rem;
    font-weight: 600;
}

.gh-content a:not(.gh-post-upgrade-cta a):not(.kg-bookmark-container):not(.kg-btn):not(.kg-nft-card-container) {
    color: var(--ghost-accent-color);
    text-decoration: underline;
    word-break: break-word;
}

.gh-content > ol,
.gh-content > ul,
.gh-content > dl,
.gh-content > p,
.kg-callout-text,
.kg-toggle-content > ol,
.kg-toggle-content > ul,
.kg-toggle-content > p {
}

.has-serif-body .gh-content > blockquote,
.has-serif-body .gh-content > ol,
.has-serif-body .gh-content > ul,
.has-serif-body .gh-content > dl,
.has-serif-body .gh-content > p,
.has-serif-body .kg-callout-text,
.has-serif-body .kg-toggle-content > ol,
.has-serif-body .kg-toggle-content > ul,
.has-serif-body .kg-toggle-content > p {
    font-family: var(--font-serif);
}

.gh-content > ul,
.gh-content > ol,
.gh-content > dl {
    padding-left: 4rem;
}

.gh-content li + li {
    margin-top: 0.8rem;
}
