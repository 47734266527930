@import 'tailwind.css';
@import 'animation.css';
@import 'knock.css';

html {
    @apply font-body overflow-x-clip;
}

body {
    @apply overflow-x-clip;
}

a {
    @apply text-primary;
}

.post-content p {
    @apply mt-4 mb-4;
}

.post-content img {
    @apply w-full rounded-lg;
}

.toc-level {
    @apply pl-8 list-disc;
}

.toc-item a.page-link {
    @apply text-sm text-gray-600 leading-6 hover:text-black;
}

.markdown p {
    @apply m-0;
}

.markdown h1 {
    @apply text-base font-semibold;
}

.pulse-purple {
    animation: animate-pulse-purple 2s infinite;
}

.skeleton-line {
    @apply relative overflow-hidden bg-gray-200;
}

.skeleton-line::after {
    @apply absolute inset-0;
    transform: translateX(-100%);
    background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
}

.scrollbar-hide {
    /* IE and Edge */
    -ms-overflow-style: none;

    /* Firefox */
    scrollbar-width: none;
}

/* Safari and Chrome */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}
