@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.animate-spin {
    animation: spin 1s linear infinite;
}

@keyframes shimmer {
    to {
        transform: translateX(100%);
    }
}

@-webkit-keyframes shimmer {
    to {
        transform: translateX(100%);
    }
}

@-webkit-keyframes animate-pulse-purple {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgb(175, 151, 253);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    }
}
